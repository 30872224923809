@use '../../../../../Assets/Styles/variables';
.div {
  text-align: center;
}
.help-h3{
  color: #73a4df;
  font-weight: bold;
  margin-bottom: 1rem;
}

.h1 {
  font-size: 2rem;
  font-weight: 140;
  color: #003661;
}

.span {
  font-size: 2rem;
  font-weight: bold;
  color: #17446c;
}
.nav-container {
  display: flex;
  flex-wrap: nowrap; 
  justify-content: center;
  text-align: center;
  gap: 180px; 
  p{
    cursor: pointer;
    font-size: 1.125rem !important;
    color: #4b4b4b;
  }
}

// .nav-link {
//   font-weight: 400;
//   color: variables.$text-color;
//   text-align: center; 
  
//   padding: 0.625rem 1.25rem; 
// }

.underline-blue, .underline-grey, .underline-yellow, .underline-green {
  color: variables.$text-color;
  text-decoration: underline;
  text-decoration-thickness: 0.125rem;
}

.underline-blue:hover, .underline-grey:hover, .underline-yellow:hover, .underline-green:hover
{
  // font-weight: 500;
  color: variables.$text-color;
}

.underline-blue {
  text-decoration-color: #a3bcdd;
  text-decoration-thickness: 0.125rem;
}

.underline-grey {
  text-decoration-color: #c3cad3;
  text-decoration-thickness: 0.125rem;
}

.underline-yellow {
  text-decoration-color: #d6f58e;
  text-decoration-thickness: 0.125rem;
}

.underline-green {
  text-decoration-color: #a1dfd9;
  text-decoration-thickness: 0.125rem;
}
.pContainer {
  width: 80%;
  // padding-top: 3.75rem;
  text-align: left;
  color: #003661;
  font-size: 1.5rem;
  padding-left: 1rem;
}
 .span-container{
  font-size: 1.3rem;
  font-weight: bold;
  color: #17446c;
 }
 .tabBtn {
 padding: 0.625rem 1.563rem;
  background-color: #006abc; 
  color: variables.$text-color-light;
  border: none;
  border-radius: 1.25rem;
  cursor: pointer;
 
}

// .tab-content-main-div{
//   min-height: 25rem;
// }

.tab-content-left{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.btn-div{
  padding-left:2.5rem;
  text-align: left;
 }
.img {
  height: auto;
  width: 100%;
}

.form-container{
  background-color: variables.$text-color-light;
  width: 70%;
  // height: 18.75rem;
  border: 0.063rem  solid #d3d3d3;
  border-radius: 0.625rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  // padding-top: 0.75rem;
  // padding-bottom: 0.75rem;
}

.form-group {
  text-align: left;
  padding: 0 0.313rem; 
}
.input{
  border-bottom:0.063rem solid variables.$landing-page-primary-color !important;
  height: auto !important;
 
}

.submit-btn{
  // padding-top: 1.25rem;
  // text-align: left;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  button{
    width: 80%;
  }
}
@media screen and (max-width: 1024px) {
  .nav-container {
    gap:6.25rem; 
  }
  .pContainer {
    width: 21.875rem;
    padding-top: 3.75rem;
    font-size: 1rem;
    padding-left: 3.125rem;
  }
  
  .h1,
  .span {
    font-size: 1.25rem;
  }

  // h3 {
  //   font-size: 0.938rem; 
  //   margin-top: 1.25rem;
 
  // }
  .form-container {
    width: 30rem;
    height: 20rem;
    margin-bottom: 2rem;
  }
  
  .input {
    height: 2.5rem !important;
  }
  
  .submit-btn {
    text-align: center;
  }
  
  .form-group {
    padding-left: 0.625rem;
  }
  .btn-div{
    padding-left: 2.5rem !important;
  }
 
}



@media screen and (max-width: 767px) {
  .h1,
  .span {
    font-size: 1.1rem;
  }

  .h1 {
    padding-bottom: 1.5rem !important;
  }

  .tab-content-left{
    h2{
      margin-bottom: 0.75rem !important;
    }
  }

  // h3 {
  //   font-size: 0.938rem; 
  //   margin-top: 1.25rem;
 
  // }

  .span-container{
    font-size: 1rem;
    font-weight: bold;
    color: #17446c;
   }
  
  .nav-container {
    gap: 0.635rem; 
    p{
      cursor: pointer;
      font-size: 0.8rem !important;
      padding: 0 0.5rem;
      color: #4b4b4b;
    }
  }
  .help-h3{
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }

  .pContainer {
    width: auto;
    padding-left: 0;
    padding-top: 1rem;
    text-align: center !important;
  }
  .img,.tab2 {
    height: 12.5rem;
    width: auto;
  }
 .btn-div{
  align-items: center;
  text-align: center;
  padding-bottom: 2rem;
 }
 .form-container {
  width: 80%;
}

.input {
  height: 2.5rem !important;
}

.submit-btn {
  text-align: center;
}

.form-group {
  padding: 0px;
}

}

