@use "../variables";
#popup-root {
    z-index: 9999;
    position: relative;
}

.popup-content {
    &.custom-modal-content {
        width: auto;
        min-width: 40%;
        max-width: 90vw;
        padding: 0px;
        border-radius: 6px;
        color: variables.$primary-neutral-text-color;

        &.custom-modal-xl-content {
            min-width: 981px;
            max-width: 90vw;

            &.custom-modal-upload-content {
                min-width: 70vw;
                max-width: 90vw;
            }

        }

        &.custom-modal-xxl-content {
            width: 90vw;
        }

        &.custom-modal-lg-content {
            min-width: 561px;
            max-width: 980px;
        }

        &.custom-modal-md-content {
            min-width: 401px;
            max-width: 560px;
        }

        &.custom-modal-sm-content {
            min-width: 100px !important;
            max-width: 400px !important;
        }

        .modal {
            display: contents;

            >.header {
                text-align: left;
                display: flex;
                border-bottom: 0px solid #ededed;
                justify-content: space-between;
                align-items: center;
                padding: 1rem 1.5rem;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    width: calc(100% - 2rem);
                    height: 1px;
                    background: #ededed;
                    bottom: -1px;
                }

                h4,
                h2 {
                    margin-bottom: 0px;
                    font-size: 1.125rem;
                }
            }

            >.container {
                padding: 1.5rem;
            }

            p {
                // word-break: break-all;
            }
        }

        // For Tables in modals

        .table thead.sticky-top {
            z-index: 0 !important;
            z-index: 999 !important;
        }
    }
}

/* Desktop Styles */
@media screen and (min-width: 992px) {
    .popup-content {
        &.custom-modal-content {
            min-width: 40%;
        }

        &.custom-modal-sm-content {
            min-width: 100px !important;
            width: auto !important;
            max-width: 400px !important;
        }
    }
}


/* Tablet Styles */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .popup-content {
        &.custom-modal-content {
            min-width: 50%;

            &.custom-modal-xl-content,
            &.custom-modal-xxl-content,
            &.custom-modal-lg-content,
            &.custom-modal-md-content {
                min-width: 90%;
                max-width: 90%;
                max-height: 90vh;
                overflow: auto;
            }

            &.custom-modal-sm-content {
                min-width: 100px !important;
                width: auto !important;
                max-width: 350px !important;
            }
        }
    }
}



/* Mobile Styles */
@media only screen and (max-width: 767px) {
    .popup-content {
        &.custom-modal-content {
            min-width: 50%;

            &.custom-modal-xl-content,
            &.custom-modal-xxl-content,
            &.custom-modal-lg-content,
            &.custom-modal-md-content {
                min-width: 90%;
                max-width: 90%;
                max-height: 90vh;
                overflow: auto;
            }

            &.custom-modal-sm-content {
                min-width: 100px !important;
                width: auto !important;
                max-width: 300px !important;
            }
        }
    }
}

// big sized desktop for integration module
@media only screen and (min-width: 1440px) {
    .popup-content {
        &.custom-modal-content {

            &.custom-modal-integration-xl-content,
            &.custom-modal-integration-xxl-content,
            &.custom-modal-integration-lg-content,
            &.custom-modal-integration-md-content {
                min-width: 80%;
                max-width: 80%;
                min-height: 80%;
                max-height: 100vh;
                overflow: auto;
            }
            .container{
                min-width: 100%;
                max-width: 100%;
                min-height: 80%;
            }
            &.custom-modal-integration-sm-content {
                min-width: 60% !important;
                width: auto !important;
                max-width: 300px !important;
            }
        }
    }
  }
  
.ticket-table-additional-information-popup-loading {
    min-height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-size: 1.2rem;
        font-weight: 500;
    }
}

.action-modal-height {
    max-height: 85vh !important;
    overflow-y: auto;
}