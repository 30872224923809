.no-data-cloud-invoice {
    min-height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media only screen and (min-width: 1440px) {
    .table-height-adjustment{
        max-height: 33rem !important;
        min-height: 33rem !important;
        overflow: auto;
    }

    .no-data-cloud-invoice {
        min-height: 30rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}