// Custom Icons 
@use '../variables';

.filter {
    background: url('../../Images/Icons/filter.svg') no-repeat center;
    display: inline-block;
    width: 20px;
    height: 20px;
    transform: translateY(-0.1rem);
}

.manage-account-azure {
    background: url('../../Images/Icons/manageIconAzure.svg') no-repeat center;
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.manage-account-csp {
    background: url('../../Images/Icons/manageIconAzure.svg') no-repeat center;
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
}


.infoIconGray {
    background: url('../../Images/Icons/infoIconGray.svg') no-repeat center;
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
}


.filter-active {
    background: url('../../Images/Icons/filterActive.svg') no-repeat center;
    display: inline-block;
    width: 19px;
    height: 19px;
    transform: translateY(-0.1rem);
}

.filter-black {
    background: url('../../Images/Icons/filterBlack.svg') no-repeat center;
    display: inline-block;
    width: 20px;
    height: 20px;
}
.pending-state-message{   
        background: url('../../Images/Icons/pending-state-message.svg') no-repeat center;
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
}

.filter-black-active {
    background: url('../../Images/Icons/filterActiveBlack.svg') no-repeat center;
    display: inline-block;
    width: 19px;
    height: 19px;
}

.infoIcon {
    background: url('../../Images/Icons/infoicon.svg') no-repeat center;
    display: inline-block;
    width: 22px;
    height: 22px;
    position: relative;
    cursor: pointer;
}

.infoIcon-support {
    background: url('../../Images/Icons/infoicon.svg') no-repeat center;
    display: inline-block;
    width: 1.375rem;
    height: 1.375rem;
    transform: scale(1);
    position: relative;
    cursor: pointer;
}

.infoIcon-support-table {
    background: url('../../Images/Icons/infoicon.svg') no-repeat center;
    background-size: 100%;
    display: inline-block;
    width: 1.4rem;
    height: 1.4rem;
    transform: scale(0.9) translateY(0.1rem);
    position: relative;
    cursor: pointer;
}

.infoIcon-suspended {
    background: url('../../Images/Icons/infoicon.svg') no-repeat center;
    background-size: 100%;
    display: inline-block;
    width: 1.4rem;
    height: 1.4rem;
    transform: scale(0.9) translateY(0.6rem);
    position: relative;
    cursor: pointer;
}


.infoIcon-support-table-big {
    transform: scale(0.9) translateY(0.3rem);
}

.refresh {
    background: url('../../Images/Icons/refresh.svg') no-repeat center;
    background-size: 100%;
    display: inline-block;
    width: 1.4rem;
    height: 1.4rem;
    transform: scale(0.9) translateY(0.1rem);
    position: relative;
    cursor: pointer;
}

.sort-icon-disabled {
    background: url('../../Images/Icons/sortIcon.svg') no-repeat center bottom;
    background-size: 75%;
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    transform: scale(1.2) translateY(-0.3rem);
    position: relative;
    cursor: pointer;
}

.sort-icon-asc {
    background: url('../../Images/Icons/sortAsc.svg') no-repeat center bottom;
    background-size: 75%;
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    transform: scale(1.2) translateY(-0.3rem);
    position: relative;
    cursor: pointer;
}

.sort-icon-desc {
    background: url('../../Images/Icons/sortDesc.svg') no-repeat center bottom;
    background-size: 75%;
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    transform: scale(1.2) translateY(-0.3rem);
    position: relative;
    cursor: pointer;
}

.sort-icon-sub-position {
    width: 1rem;
    height: 1rem;
    transform: scale(1.3) translateY(-0.1rem);
    margin-left: 6px;
}


.closebtn {
    background: url('../../Images/Icons/closebtn.svg') no-repeat center;
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-size: 1.2rem;
    position: relative;
    cursor: pointer;
}


.copy {
    background: url('../../Images/Icons/copy.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.0rem);
}

.dashboard-arrow {
    background: url('../../Images/Icons/dashboard-arrow.svg') no-repeat center;
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    transform: translateY(0.3rem);
}

.dashboard-circle-dot {
    background: url('../../Images/Icons/dashboard-circle-dot.svg') no-repeat center;
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    transform: translateY(0.3rem);
}

.dashboard-download {
    background: url('../../Images/Icons/dashboard-download.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 20px;
    position: relative;
    transform: translateY(0.3rem);
}

.dashboard-cross {
    background: url('../../Images/Icons/dashboard-cross.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 20px;
    position: relative;
    transform: translateY(0.3rem);
}

.dashboard-actions-icon1 {
    background: url('../../Images/Icons/dashboard-actions-icon1.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.dashboard-actions-icon2 {
    background: url('../../Images/Icons/dashboard-actions-icon2.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.dashboard-green-arrow {
    background: url('../../Images/Icons/dashboard-green-arrow.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.dashboard-red-arrow {
    background: url('../../Images/Icons/dashboard-red-arrow.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.status-active {
    background: url('../../Images/Icons/status-active.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.status-cancelled {
    background: url('../../Images/Icons/status-cancelled.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.dashboard-change-status {
    background: url('../../Images/Icons/dashboard-change-status.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
}

.dashboard-change-status-disabled {
    background: url('../../Images/Icons/dashboard-change-status-disabled.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
}

.renewal-change-status {
    background: url('../../Images/Icons/renewal-settings-icon.svg') no-repeat center;
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
}

.renewal-change-status-disabled {
    background: url('../../Images/Icons/renewal-settings-icon.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    opacity: 0.6;
    cursor: not-allowed;
    filter: grayscale(100%);
  filter: brightness(50%);
  filter: contrast(50%);
  filter: sepia(100%);
}

.status-success {
    background: url('../../Images/Icons/status-active.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
}

.status-suspended {
    background: url('../../Images/Icons/status-suspended.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.status-deleted {
    background: url('../../Images/Icons/status-deleted.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem) scale(1.2);
}

.status-disabled {
    background: url('../../Images/Icons/status-disabled.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem) scale(1.2);
}

.status-expired {
    background: url('../../Images/Icons/status-expired.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem) scale(1.2);
}

.status-deactive {
    background: url('../../Images/Icons/status-deactive.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem) scale(1.2);
}

.status-outstanding {
    background: url('../../Images/Icons/status-outstanding.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.status-outstanding-new {
    background: url('../../Images/Icons/status-outstanding-new.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}


.status-overdue {
    background: url('../../Images/Icons/status-overdue.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.status-paid {
    background: url('../../Images/Icons/status-paid.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.status-paid-new {
    background: url('../../Images/Icons/status-paid-new.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}


.status-inprogress {
    background: url('../../Images/Icons/status-inprogress.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.status-received {
    background: url('../../Images/Icons/status-received.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.status-suspended {
    background: url('../../Images/Icons/status-suspended.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.status-deleted {
    background: url('../../Images/Icons/status-deleted.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem) scale(1.2);
}

.status-disabled {
    background: url('../../Images/Icons/status-disabled.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem) scale(1.2);
}

.status-expired {
    background: url('../../Images/Icons/status-expired.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem) scale(1.2);
}

.status-deactive {
    background: url('../../Images/Icons/status-deactive.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem) scale(1.2);
}


.status-outstanding {
    background: url('../../Images/Icons/status-outstanding.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.status-outstanding-new {
    background: url('../../Images/Icons/status-outstanding-new.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}


.status-overdue {
    background: url('../../Images/Icons/status-overdue.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}


.status-paid {
    background: url('../../Images/Icons/status-paid.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.status-paid-new {
    background: url('../../Images/Icons/status-paid-new.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}


.status-inprogress {
    background: url('../../Images/Icons/status-inprogress.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.status-failed {
    background: url('../../Images/Icons/status-failed.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.status-error {
    background: url('../../Images/Icons/status-failed.svg') no-repeat center;
    background-size: 16px;
    display: inline-block;
    width: 26px;
    height: 16px;
    position: relative;
}

.step-completed {
    background: url('../../Images/Icons/fill-circle-blue.svg') no-repeat center;
    background-size: 1.5rem;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    position: relative;
}

.status-pending {
    background: url('../../Images/Icons/status-pending.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.gdap-bullet {
    // background: url('../../Images/Icons/gdap-bullet.svg') no-repeat center;
    background: variables.$primary-color;
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    position: relative;
    transform: translateY(-0.05rem) !important;
    border-radius: 0.5rem;
    margin-right: 0.3rem;
}

.Edit-Icon {
    background: url('../../Images/Icons/Edit-Icon.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.edit {
    background: url('../../Images/Icons/edit-icons-card.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.delete {
    background: url('../../Images/Icons/delete.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.visa-mastercard {
    background: url('../../Images/Icons/visa-mastercard.svg') no-repeat center;
    display: inline-block;
    width: 8rem;
    height: 4rem;
    position: relative;
    transform: translateY(0.3rem);
}

.credit-card-defualt-icon {
    background: url('../../Images/Icons/credit-card-default.svg') no-repeat center;
    display: inline-block;
    height: 1rem; 
    width: 2rem;
    position: relative;
}
.credit-card-visa-icon {
    background: url('../../Images/Icons/credit-card-visa-logo.svg') no-repeat center;
    display: inline-block;
    background-size: 2rem 1.5rem;
    height: 1rem; 
    width: 3rem;
    transform: scale(0.8);
    position: relative;
}
.credit-card-master-icon {
    background: url('../../Images/Icons/credit-card-master-logo.svg') no-repeat center;
    background-size: 2rem 1rem;
    display: inline-block;
    height: 1rem; 
    width: 2rem;
    position: relative;
}

.change-status {
    background: url('../../Images/Icons/change-status.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);

}

.upgrade {
    background: url('../../Images/Icons/upgrade.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);

}

.trial-to-paid {
    background: url('../../Images/Icons/trialToPaid.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);

}

.purchase-addon {
    background: url('../../Images/Icons/purchase-addon.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);

}

.downgrade {
    background: url('../../Images/Icons/downgrade.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);

}

.change-payment-plan {
    background: url('../../Images/Icons/change-payment-plan.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);

}

.renewal-settings {
    background: url('../../Images/Icons/renewal-settings.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);

}

.status-partiallyCompleted {
    background: url('../../Images/Icons/status-partiallyCompleted.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
}

.quantity-active {
    background: url('../../Images/Icons/quantity-active.svg') no-repeat center;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    position: relative;
    transform: translateY(0.3rem);
    cursor: pointer;
}

.quantity-disable {
    background: url('../../Images/Icons/quantity-disable.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
    cursor: pointer;
}

.quantity-tick {
    background: url('../../Images/Icons/quantity-tick.svg') no-repeat center;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    position: relative;
    transform: translateY(0.3rem);
    cursor: pointer;
}

.tick-grey {
    background: url('../../Images/Icons/tick-grey.svg') no-repeat center;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    position: relative;
    transform: translateY(0.3rem);
    cursor: pointer;
}

.quantity-cross {
    background: url('../../Images/Icons/quantity-cross.svg') no-repeat center;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    position: relative;
    transform: translateY(0.3rem);
    cursor: pointer;
}

.feedback-icon {
    background: url('../../Images//Icons/feedback-icon.svg') no-repeat center;
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    transform: scale(0.9) translateY(0.1rem);
    position: relative;
    cursor: pointer;
}

.announcement-icon {
    background: url('../../Images//Icons/announcement-icon.svg') no-repeat center;
    display: inline-block;
    height: 1.75rem;
    width: 1.75rem;
    transform: scale(0.9) translateY(-0.2rem);
    position: relative;
    cursor: pointer;
}

.configure-up {
    background: url('../../Images//Icons/configure-up.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
    cursor: pointer;
}


.configure-down {
    background: url('../../Images//Icons/configure-down.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
    cursor: pointer;
}

.gdap-down-button {
    background: url('../../Images//Icons/gdap-down-button.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
    cursor: pointer;
}

.gdap-up-button {
    background: url('../../Images//Icons/gdap-up-button.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
    cursor: pointer;
}

.up-accordion {
    background: url('../../Images/Icons/up-accordion.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
    cursor: pointer;
}

.down-accordion {
    background: url('../../Images/Icons/down-accordion.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
    cursor: pointer;
}

.right-accordion {
    background: url('../../Images/Icons/right-accordion.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
    cursor: pointer;
}

.gdap-expandable {
    background: url('../../Images/Icons/gdap-expandable.svg') no-repeat center;
    display: inline-block;
    width: 8rem;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
    cursor: pointer;
}

.down-accordion-gcp {
    background: url('../../Images/Icons/down-accordion-gcp.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.3rem);
    cursor: pointer;
}

.plus-icon-add-user {
    background: url('../../Images/Icons/plus-icon-add-user.svg') no-repeat center;
    display: inline-block;
    width: 14px;
    height: 14px;
    position: relative;
    transform: translateY(0.3rem);
    cursor: pointer;
}

.external-link {
    background: url('../../Images/Icons/external-link.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    transform: translateY(0.2rem) scale(1.1);
    background-size: 12px;
}

.download {
    background: url('../../Images/Icons/download.svg') no-repeat center;
    display: inline-block;
    width: 20px;
    height: 20px;
}

.clicktocopy {
    position: relative;
    overflow: visible;
    display: inline-block;

    &:hover {
        .copystatus {
            display: inline-block;
        }
    }

    .copystatus {
        background: rgb(207, 207, 207);
        color: rgb(0, 0, 0);
        position: absolute;
        left: 4px;
        top: -25px;
        width: 80px;
        margin-top: 0px;
        font-size: 0.75em;
        font-weight: normal;
        line-height: 1.5;
        display: none;
        padding: 3px;
        text-align: center;
        border-radius: 4px;
    }
}

.ticket {
    background: url('../../Images/Icons/ticket.svg') no-repeat center;
    display: inline-block;
    width: 18px;
    height: 14px;
    position: relative;
    cursor: pointer;
}

.search {
    background: url('../../Images/Icons/search-black.svg') no-repeat center;
    display: inline-block;
    width: 20px;
    height: 21px;
    position: relative;
}

.searchgrey {
    background: url('../../Images/Icons/search.svg') no-repeat center;
    display: inline-block;
    width: 20px;
    height: 21px;
    position: relative;
}

.lock {
    background: url('../../Images/Icons/lock.svg') no-repeat center;
    display: inline-block;
    width: 12px;
    height: 15px;
    position: relative;
}

.trash {
    background: url('../../Images/Icons/trash.svg') no-repeat center;
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
}

.credit-card {
    background: url('../../Images/Icons/credit-card.svg') no-repeat center;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
}

.chevrondown {
    background: url('../../Images/Icons/chevrondown.svg') no-repeat center;
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
}

.editpencil {
    background: url('../../Images/Icons/edit.svg') no-repeat center;
    display: inline-block;
    width: 18px;
    height: 20px;
    position: relative;
    cursor: pointer;

    &.disabled {
        opacity: 0.5;
    }
}
.warning{
    background: url('../../Images/Icons/warning.svg') no-repeat center;
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;

    &.disabled {
        opacity: 0.5;
    }
}

.add-widget {
    background: url('../../Images/Icons/add-widget.svg') no-repeat center;
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
    cursor: pointer;
    transform: scale(1.3);
}

.profile {
    background: url('../../Images/Icons/profile.svg') no-repeat 10px center;
    background-size: 1.1rem;
    display: inline-block;
    width: auto;
    height: auto;
    position: relative;
    cursor: pointer;
    padding-left: 30px !important;

    &:hover {
        background: url('../../Images/Icons/profile-hover.svg') no-repeat 10px center;
        background-size: 1.1rem;
    }
}
.credit_card{
    background: url('../../Images/Icons/credit_card.svg') no-repeat 10px center;
    background-size: 1.1rem;
    display: inline-block;
    width: auto;
    height: auto;
    position: relative;
    cursor: pointer;
    padding-left: 30px !important;

    &:hover {
        background: url('../../Images/Icons/credit_card-wh.svg') no-repeat 10px center;
        background-size: 1.1rem;
    }
}
.add-widget-customers {
    background: url('../../Images/Icons/add-widget-customers.svg') no-repeat center;
    display: inline-block;
    width: 100px;
    height: 100px;
    position: relative;
    transform: scale(1.6);
}

.add-trendingplans {
    background: url('../../Images/Icons/add-trendingplans.svg') no-repeat center;
    display: inline-block;
    width: 6.25rem;
    height: 6.25rem;
    position: relative;
    transform: scale(1.6);
}

.add-subscription-overview {
    background: url('../../Images/Icons/add-subscription-overview.svg') no-repeat center;
    display: inline-block;
    width: 6.25rem;
    height: 6.25rem;
    position: relative;
    transform: scale(1.6);
}

.add-customers {
    background: url('../../Images/Icons/add-customers.svg') no-repeat center;
    display: inline-block;
    width: 6.25rem;
    height: 6.25rem;
    position: relative;
    transform: scale(1.6);
}

.add-subscripnrenewal {
    background: url('../../Images/Icons/add-subscripnrenewal.svg') no-repeat center;
    display: inline-block;
    width: 6.25rem;
    height: 6.25rem;
    position: relative;
    transform: scale(1.6);
}

.add-seatutilization {
    background: url('../../Images/Icons/add-seatutilization.svg') no-repeat center;
    display: inline-block;
    width: 6.25rem;
    height: 6.25rem;
    position: relative;
    transform: scale(1.6);
}

.add-subscription {
    background: url('../../Images/Icons/add-subscription.svg') no-repeat center;
    display: inline-block;
    width: 6.25rem;
    height: 6.25rem;
    position: relative;
    transform: scale(1.6);
}

.add-newcustomer {
    background: url('../../Images/Icons/add-newcustomer.svg') no-repeat center;
    display: inline-block;
    width: 6.25rem;
    height: 6.25rem;
    position: relative;
    transform: scale(1.6);
}

.pdf-icon {
    background: url('../../Images/Icons/pdf-icon.svg') no-repeat center;
    display: inline-block;
    width: 6.25rem;
    height: 6.25rem;
    position: relative;
    transform: scale(1.6);
}

.add-unpaid-invoices-summary {
    background: url('../../Images/Icons/add-unpaid-invoices-summary.svg') no-repeat center;
    display: inline-block;
    width: 6.25rem;
    height: 6.25rem;
    position: relative;
    transform: scale(1.6);
}

.add-vendor-revenue-contribution {
    background: url('../../Images/Icons/add-vendor-revenue-contribution.svg') no-repeat center;
    display: inline-block;
    width: 6.25rem;
    height: 6.25rem;
    position: relative;
    transform: scale(1.6);
}

.add-invoice-amount {
    background: url('../../Images/Icons/add-invoice-amount.svg') no-repeat center;
    display: inline-block;
    width: 6.25rem;
    height: 6.25rem;
    position: relative;
    transform: scale(1.6);
}

.subscriptions-icon {
    background: url('../../Images/Icons/subscriptions-icon.svg') no-repeat 10px center;
    background-size: 1.1rem;
    display: inline-block;
    width: auto;
    height: auto;
    position: relative;
    cursor: pointer;
    padding-left: 30px !important;

    &:hover {
        background: url('../../Images/Icons/subscriptions-icon-white.svg') no-repeat 10px center;
        background-size: 1.1rem;
    }
}

.User-management-icon {
    background: url('../../Images/Icons/User-management-icon.svg') no-repeat 10px center;
    background-size: 1.1rem;
    display: inline-block;
    width: auto;
    height: auto;
    position: relative;
    cursor: pointer;
    padding-left: 30px !important;

    &:hover {
        background: url('../../Images/Icons/User-management-icon-white.svg') no-repeat 10px center;
        background-size: 1.1rem;
    }
}

.logout {
    background: url('../../Images/Icons/right-arrow-box.svg') no-repeat 10px center;
    background-size: 1.25rem;
    display: inline-block;
    width: auto;
    height: auto;
    position: relative;
    cursor: pointer;
    padding-left: 30px !important;

    &:hover {
        background: url('../../Images/Icons/right-arrow-box-hover.svg') no-repeat 10px center;
        background-size: 1.25rem;
    }
}

.account-balance {
    background: url('../../Images/Icons/account-balance.svg') no-repeat;
    background-position: 50%;
    color: variables.$icons-secondary;
    font-size: 0;
    height: 19px;
    line-height: 14px;
    text-align: center;
    -webkit-transform: scale(.9);
    transform: scale(.9);
    width: 30px;
}

.eye {
    background: url('../../Images/Icons/eye.svg') no-repeat;
    background-position: 50%;
    color: variables.$icons-secondary;
    font-size: 0;
    height: 16px;
    line-height: 14px;
    text-align: center;
    -webkit-transform: scale(.9);
    transform: scale(.9);
    width: 30px;

    &.hide-eye {
        background-image: url(../../Images/Icons/eyeHide.svg);
        background-repeat: no-repeat;
        font-size: 30px;
        font-weight: 100;
    }

    &.btn-eye {
        cursor: pointer;
    }
}

.back-blue {
    background: url('../../Images/Icons/back-blue.svg') no-repeat;
    background-position: center;
    color: variables.$icons-secondary;
    height: auto;
    line-height: initial;
    text-align: center;
    transform: scale(1);
    width: 1rem;
    display: inline-block;
}

.back {
    background: url('../../Images/Icons/chevronleft.svg') no-repeat;
    background-position: center;
    color: variables.$icons-secondary;
    height: auto;
    line-height: initial;
    text-align: center;
    transform: scale(1);
    width: 1rem;
    display: inline-block;
}

.plus-grey {
    background: url('../../Images/Icons/plus_grey.svg') no-repeat center;
    background-size: 100%;
    display: inline-block;
    width: 0.75rem;
    height: 0.75rem;
    position: relative;
    cursor: pointer;
}

.success-page {
    background: url('../../Images/Icons/success-tick.svg') no-repeat center;
    background-size: 100%;
    display: inline-block;
    width: 8rem;
    height: 8rem;
}

.failed-page {
    background: url('../../Images/Icons/quantity-cross.svg') no-repeat center;
    background-size: 100%;
    display: inline-block;
    width: 8rem;
    height: 8rem;
}

.order-cross {
    background: url('../../Images/Icons/order-cross.svg') no-repeat center;
    background-size: 100%;
    display: inline-block;
    width: 8rem;
    height: 8rem;
}

.all-service {
    background: url('../../Images/Icons/all-service.svg') no-repeat center;
    background-size: 100%;
    display: inline-block;
    width: 1rem;
    height: 1rem;
}

.provision-microsoftsaas {
    background: url('../../Images/Icons/provision-microsoftsaas.svg') no-repeat center;
    background-size: 100%;
    display: inline-block;
    width: 1rem;
    height: 1rem;
}

.provision-microsoftazure {
    background: url('../../Images/Icons/provision-microsoftazure.svg') no-repeat center;
    background-size: 100%;
    display: inline-block;
    width: 1rem;
    height: 1rem;
}

.provision-googleworkspace {
    background: url('../../Images/Icons/provision-googleworkspace.svg') no-repeat center;
    background-size: 100%;
    display: inline-block;
    width: 1rem;
    height: 1rem;
}

.provision-GCP {
    background: url('../../Images/Icons/provision-GCP.svg') no-repeat center;
    background-size: 100%;
    display: inline-block;
    width: 1rem;
    height: 1rem;
}

.provision-acronis1 {
    background: url('../../Images/Icons/provision-acronis1.svg') no-repeat center;
    background-size: 100%;
    display: inline-block;
    width: 1rem;
    height: 1rem;
}

@media only screen and (max-width: 767px) {
    .copy {
        transform: translateY(0.1rem) scale(0.8);
    }

    .external-link {
        transform: translateY(0.3rem) scale(0.8);
    }

    .infoIcon-support {
        background: url('../../Images/Icons/infoicon.svg') no-repeat center;
        display: inline-block;
        width: 1.375rem;
        height: 1.375rem;
        transform: scale(0.7);
        position: relative;
        cursor: pointer;
    }

    .infoIcon-suspended {
        background: url('../../Images/Icons/infoicon.svg') no-repeat center;
        display: inline-block;
        width: 1.4rem;
        height: 1.4rem;
        transform: scale(0.7) translateY(0.5rem);
        position: relative;
        cursor: pointer;
    }

    .infoIcon-suspended {
        background: url('../../Images/Icons/infoicon.svg') no-repeat center;
        background-size: 100%;
        display: inline-block;
        width: 1.4rem;
        height: 1.4rem;
        transform: scale(0.9) translateY(0.3rem);
        position: relative;
        cursor: pointer;
    }

    .infoIcon-support-table-big {
        transform: scale(0.7) translateY(0.3rem);
    }

    .sort-icon-asc {
        transform: scale(0.8) translateY(0.05rem);
    }

    .sort-icon-disabled {
        transform: scale(0.8) translateY(0.05rem);
    }

    .sort-icon-desc {
        transform: scale(0.8) translateY(0.05rem);
    }

    .editpencil {
        display: none !important;
    }

    .filter {
        transform: scale(0.85);
    }

    .filter-active {
        transform: scale(0.85);
    }

    .filter-black {
        transform: scale(0.85);
    }

    .filter-black-active {
        transform: scale(0.85);
    }

    .search {
        transform: scale(0.85);
    }

    .searchgrey {
        transform: scale(0.85);
    }

    .ticket {
        transform: scale(0.75);
    }

    .infoIcon {
        transform: scale(0.75);
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .filter {
        transform: scale(0.75);
    }

    .filter-active {
        transform: scale(0.75);
    }

    .filter-black {
        transform: scale(0.75);
    }

    .filter-black-active {
        transform: scale(0.75);
    }

    .search {
        transform: scale(0.75);
    }

    .searchgrey {
        transform: scale(0.75);
    }
    .add-widget-customers {
        transform: scale(1);
    }
    
    .add-trendingplans {
        transform: scale(1);
    }
    
    .add-subscription-overview {
        transform: scale(1);
    }
    
    .add-customers {
        transform: scale(1);
    }
    
    .add-subscripnrenewal {
        transform: scale(1);
    }
    
    .add-seatutilization {
        transform: scale(1);
    }
    
    .add-subscription {
        transform: scale(1);
    }
    
    .add-newcustomer {
        transform: scale(1);
    }
    
    .pdf-icon {
        transform: scale(1);
    }
    
    .add-unpaid-invoices-summary {
        transform: scale(1);
    }
    
    .add-vendor-revenue-contribution {
        transform: scale(1);
    }
    
    .add-invoice-amount {
        transform: scale(1);
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .copy {
        transform: translateY(0.1rem) scale(1);
    }

    .filter {
        transform: scale(0.85);
    }

    .filter-active {
        transform: scale(0.85);
    }

    .filter-black {
        transform: scale(0.85);
    }

    .filter-black-active {
        transform: scale(0.85);
    }

    .search {
        transform: scale(0.85);
    }

    .searchgrey {
        transform: scale(0.85);
    }
    .add-widget-customers {
        transform: scale(1);
    }
    
    .add-trendingplans {
        transform: scale(1);
    }
    
    .add-subscription-overview {
        transform: scale(1);
    }
    
    .add-customers {
        transform: scale(1);
    }
    
    .add-subscripnrenewal {
        transform: scale(1);
    }
    
    .add-seatutilization {
        transform: scale(1);
    }
    
    .add-subscription {
        transform: scale(1);
    }
    
    .add-newcustomer {
        transform: scale(1);
    }
    
    .pdf-icon {
        transform: scale(1);
    }
    
    .add-unpaid-invoices-summary {
        transform: scale(1);
    }
    
    .add-vendor-revenue-contribution {
        transform: scale(1);
    }
    
    .add-invoice-amount {
        transform: scale(1);
    }
}

.order-summery-toggle {
    margin-left: 0px;
    z-index: 9;
    position: relative;
    background: url('../../Images/Icons/left-arrow.svg') no-repeat;
    background-size: 2.5rem;
    background-position: -0.5rem -0.0rem;
    background-color: transparent;
    border-radius: 24px;
    cursor: pointer;
    transform: rotate(0deg) scale(1);
    transition: all 0.2s;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    // box-shadow: 0px 4px 2px 0px variables.$text-color;
    margin-top: 10px;

    &.active {
        background: url('../../Images/Icons/right-arrow.svg') no-repeat;
        background-size: 2.5rem;
        margin-right: -10px;
    }
}

// Scroll To Top
.scrollToTop {
    position: fixed;
    width: 3rem;
    height: 3rem;
    right: 1rem;
    bottom: 1rem;
    z-index: 9999;
    cursor: pointer;
    background: variables.$primary-color;
    font-size: 0;
    border-radius: 6px;
    text-align: center;
    line-height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: #3599DA;
    }
    svg{
        path{
            color: variables.$text-color-light !important; 
        }
    }
}

@media screen and (max-width: 767px) {
    .sort-icon-asc {
        background: url('../../Images/Icons/sortAsc.svg') no-repeat center bottom;
        background-size: 100%;
    }

    .sort-icon-disabled {
        background: url('../../Images/Icons/sortIcon.svg') no-repeat center bottom;
        background-size: 100%;
    }

    .sort-icon-desc {
        background: url('../../Images/Icons/sortDesc.svg') no-repeat center bottom;
        background-size: 100%;
    }

    .add-widget-customers {
        transform: scale(1);
    }
    
    .add-trendingplans {
        transform: scale(1);
    }
    
    .add-subscription-overview {
        transform: scale(1);
    }
    
    .add-customers {
        transform: scale(1);
    }
    
    .add-subscripnrenewal {
        transform: scale(1);
    }
    
    .add-seatutilization {
        transform: scale(1);
    }
    
    .add-subscription {
        transform: scale(1);
    }
    
    .add-newcustomer {
        transform: scale(1);
    }
    
    .pdf-icon {
        transform: scale(1);
    }
    
    .add-unpaid-invoices-summary {
        transform: scale(1);
    }
    
    .add-vendor-revenue-contribution {
        transform: scale(1);
    }
    
    .add-invoice-amount {
        transform: scale(1);
    }
}


@media screen and (max-width: 992px) {
.feedback-icon {
    background: url('../../Images//Icons/feedback-icon.svg') no-repeat center;
    display: inline-block;
    height: 1.5rem;
    width: 2rem;
    transform: scale(0.9) translateY(0.1rem);
    position: relative;
    cursor: pointer;
}
.announcement-icon {
    background: url('../../Images//Icons/announcement-icon.svg') no-repeat center;
    display: inline-block;
    height: 1.75rem;
    width: 2.25rem;
    transform: scale(0.9) translateY(-0.2rem);
    position: relative;
    cursor: pointer;
}

}