@use '../../../../../../../Assets/Styles/variables';
.nav-container {
    display: flex;
    flex-wrap: nowrap;
    text-align: center;
    gap: 5rem;
    .nav-link{
        &:active{
            background-color: variables.$primary-color;
        }
    }
}
.nav-headings{
    color: variables.$text-color !important;
}
.border-bottom-color {
    color: variables.$text-color !important;
    position: relative; 
    
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 0.15rem;
        background-color: variables.$primary-color; 
        left: 0;
        bottom: -54%; 
        transform: translateY(-100%);
        visibility: visible; 
    }
}

  .popup-container{
    width: 10vh !important;
    background-color: variables.$text-color;
  }


  @media screen and (max-width: 993px) {
    .border-bottom-color {
        color: variables.$text-color !important;
        position: relative; 
        
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 0.15rem;
            background-color: variables.$primary-color; 
            left: 0;
            bottom: -53%; 
            transform: translateY(-100%);
            visibility: visible; 
        }
    }
  }

  @media screen and (max-width: 767px) {
    .nav-container {
        display: flex;
       gap: 1rem;
       padding: 0;
    }
    .mobile-heading{
        padding: 0.4rem !important;
       }
       .border-bottom-color {
        color: variables.$text-color !important;
        position: relative; 
        
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 0.15rem;
            background-color: variables.$primary-color; 
            left: 0;
            bottom: -31%; 
            transform: translateY(-100%);
            visibility: visible; 
        }
    }
    .mobile-padding{
        padding-left: 0 !important;
    }



  }
